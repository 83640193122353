import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    leads: {},
    xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth.xios);
            return true;
        } else {
            return false;
        }
    },
    lead_field_fetch(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            data.append('parent_type', 'lead');
            return context.state.xios.post('crm_fields_all', data);
        }
    },
    deal_field_fetch(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            data.append('parent_type', 'deal');
            return context.state.xios.post('crm_fields_all', data);
        }
    },
    update_to_visible(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('crm_fields_visible', value);
        }
    },
    update_to_none(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('crm_fields_none', value);
        }
    },
    sync(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.get('crm_fields_sync');
        }
    },


};

const mutations = {
    INIT(state, payload) {
        state.xios = payload;
    }

};
const getters = {

};

const leads = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default leads;