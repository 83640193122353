import Vue from "vue";
import Vuex from "vuex";
import VueAxios from 'vue-axios';
import axios from 'axios';
import api from "./api";
import user from "./modules/user";
import search from "./modules/search";
import lead from "./modules/lead";
import agent from "./modules/agent";
import general from "./modules/general";
import queue from "./modules/queue";
import deal from "./modules/deal";
import setting from "./modules/setting";
import conversation from "./modules/conversation";
import notification from "./modules/notification";
import transcribe from './transcribe';
import call from './modules/call';
import combined_notification from './modules/combined_notification';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  modules: {
    api: api,
    user: user,
    search: search,
    lead: lead,
    agent: agent,
    general: general,
    queue: queue,
    deal: deal,
    setting: setting,
    conversation: conversation,
    notification: notification,
    combined_notification: combined_notification,
    transcribe: transcribe,
    call: call
  }
})

/*
const moduleA = {
  state: () => ({ ... }),
  mutations: { ... },
  actions: { ... },
  getters: { ... }
}

const moduleB = {
  state: () => ({ ... }),
  mutations: { ... },
  actions: { ... }
}

const store = new Vuex.Store({
  modules: {
    a: moduleA,
    b: moduleB
  }
})

store.state.a // -> `moduleA`'s state
store.state.b // -> `moduleB`'s state
const api = axios.create({
                baseURL: `http://127.0.0.1:8000/api/`,
                withCredentials: true,
                headers: {
                    'Authorization': '',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            })
*/
