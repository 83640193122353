<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar dash-nav" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button/>
      </div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>
    <div class="search-bar input-group" @click="()=>{dashboardSearchModal = true;focus_search()}">
      <!-- <input type="text" class="form-control" placeholder="Search...">
      <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div> -->
      <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
             viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <g id="search">
                <circle class="st2" cx="12.4" cy="12.4" r="10.8"/>
              <line class="st2" x1="30.5" y1="30.4" x2="20.6" y2="20.6"/>
            </g>
            </svg>
        <input id="searchMainInput" type="text" class="form-control"
               @click="()=>{dashboardSearchModal = true;focus_search()}" placeholder="Filter & Search">
        <!--<i class="tim-icons icon-zoom-split"></i>-->
      </button>
      <modal :show.sync="dashboardSearchModal" :centered="false" :show-close="true" class="searchResultMain"
             id="SearchResult">
        <div class="relative-d">
          <input placeholder="Search" id="search_popup_input" @keyup="searching()" v-model="search_query">
          <svg class="search-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               version="1.1" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;"
               xml:space="preserve">

            <g id="searchResults_icon">
                <circle class="st2" cx="12.4" cy="12.4" r="10.8"/>
              <line class="st2" x1="30.5" y1="30.4" x2="20.6" y2="20.6"/>
            </g>
            </svg>
          <a href="javascript:void(0);" class="closebtn-serch" @click="clearSearch();">×</a>
        </div>
        <progress-bar class="fr-mr" :bar-color="progress_bar.bar_color" :val="progress_bar.val" :max="progress_bar.max"
                      :spacing="progress_bar.spacing" :text="progress_bar.text" :text-align="progress_bar.text_align"
                      :text-position="progress_bar.text_position" :bar-border-radius="progress_bar.bar_border_radius"
                      :text-fg-color="progress_bar.text_fg_color"></progress-bar>
        <div class="main-drop-wrap" :style="search_height">
          <div class="Contacts-main" v-if="search_results.deals.length > 0">
            <h2>Deals</h2>
            <div class="allDataContacts">
              <p class="active" v-for="deal of search_results.deals" :key="deal.ID">
                <router-link @click.native="clearSearch" style="color : white;" :to="{ name :'View Deal', params:{id:deal.id}}">{{
                    deal.TITLE
                  }}
                </router-link>
              </p>
            </div>
            <button class="More-main">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                   y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <g id="more_button_svg">
                        <g>
                            <circle class="st28" cx="4" cy="16" r="2.9"/>
                          <circle class="st28" cx="16" cy="16" r="2.9"/>
                          <circle class="st28" cx="28" cy="16" r="2.9"/>
                        </g>
                    </g>
                    </svg>
              <p>view more</p>
            </button>
          </div>
          <div class="Employess-main" v-if="search_results.employees.length > 0">
            <h2>Employees</h2>
            <div class="allData">
              <div class="employeeDataActive" v-for="employee of search_results.employees" :key="employee.id">
                <img src="../../../public/img/svg/agents.svg">
                <p>
                  <router-link @click.native="clearSearch" style="color : #222a42;" :to="{ name :'View Agent', params:{id:employee.id}}">
                    {{ employee.name }}
                  </router-link>
                </p>
              </div>
            </div>
            <button class="More-main">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                   y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <g id="more_button_svg">
                        <g>
                            <circle class="st28" cx="4" cy="16" r="2.9"/>
                          <circle class="st28" cx="16" cy="16" r="2.9"/>
                          <circle class="st28" cx="28" cy="16" r="2.9"/>
                        </g>
                    </g>
                    </svg>
              <p>more</p>
            </button>
          </div>
          <div class="Contacts-main" v-if="search_results.contacts.length > 0">
            <h2>Contacts</h2>
            <div class="allDataContacts">
              <p class="active" v-for="contact of search_results.contacts" :key="contact.ID">
                {{
                  (contact.NAME ? contact.NAME + ' ' : '') + (contact.SECOND_NAME ? contact.SECOND_NAME + ' ' : '') + (contact.LAST_NAME ? contact.LAST_NAME + ' ' : '')
                }}
              </p>
            </div>
            <button class="More-main">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                   y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <g id="more_button_svg">
                        <g>
                            <circle class="st28" cx="4" cy="16" r="2.9"/>
                          <circle class="st28" cx="16" cy="16" r="2.9"/>
                          <circle class="st28" cx="28" cy="16" r="2.9"/>
                        </g>
                    </g>
                    </svg>
              <p>view more</p>
            </button>
          </div>
          <div class="Contacts-main" v-if="search_results.leads.length > 0">
            <h2>Leads</h2>
            <div class="allDataContacts">
              <p class="active" v-for="lead of search_results.leads" :key="lead.ID">
                <router-link @click.native="clearSearch"  style="color : white;" :to="{ name :'View Lead', params:{id:lead.id}}">{{
                    lead.TITLE
                  }}
                </router-link>
              </p>
            </div>
            <button class="More-main">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                   y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <g id="more_button_svg">
                        <g>
                            <circle class="st28" cx="4" cy="16" r="2.9"/>
                          <circle class="st28" cx="16" cy="16" r="2.9"/>
                          <circle class="st28" cx="28" cy="16" r="2.9"/>
                        </g>
                    </g>
                    </svg>
              <p>view more</p>
            </button>
          </div>

        </div>
      </modal>
      <!-- You can choose types of search input -->
    </div>
    <!-- <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">

        <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item">
            <template slot="title">
                <div class="notification d-none d-lg-block d-xl-block" v-if="notification_toggle"></div>
                <i class="tim-icons icon-sound-wave" @click="toggle_nav()"></i>
                <p class="d-lg-none">New Notifications</p>
            </template>
            <li class="nav-link" v-for="notifcation in notifications" :key="notifcation.id">
                <router-link :to="{ name :'Message', params:{type:notifcation.object_type.split('\\')[(notifcation.object_type.split('\\').length-1)].toLowerCase(),id:notifcation.object.id}}">
                    <a class="nav-item dropdown-item">{{notifcation.object.TITLE}} : {{notifcation.parent.text}}</a>
                </router-link>
            </li>
        </base-dropdown>

        <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link" menu-classes="dropdown-navbar">
            <template slot="title">
                <div class="photo"><img :src="base_url('img/mike.jpg')" /></div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Admin</p>
            </template>
            <li class="nav-link">
                <router-link :to="{ name :'User Profile'}" class="nav-item dropdown-item">Profile</router-link>
            </li>
            <div class="dropdown-divider"></div>
            <li class="nav-link">
                <a class="nav-item dropdown-item" @click="logout">Log out</a>
            </li>
        </base-dropdown>
    </ul> -->
  </base-nav>
</template>

<script>
import {
  CollapseTransition
} from 'vue2-transitions';
import {
  BaseNav,
  Modal
} from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import {transition} from 'd3';

const debounce = {
  interval: null,
  wait: 500,
  func: () => {
  },
  __: function (f, w) {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.wait = w;
    this.func = f;
    this.interval = setTimeout(debounce.func, debounce.wait);
  }
};

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    routeName() {
      const {
        name
      } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      notification_toggle: false,
      user: {},
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      dashboardSearchModal: false,
      searchQuery: '',
      channel: {
        sms: {},
        call: {}
      },
      notifications: [],
      search_query: '',
      search_results: {
        contacts: [],
        deals: [],
        leads: [],
        employees: []
      },
      search_height: '',
      progress_bar: {
        intervals: [],
        val: 0,
        max: 100,
        size: 3,
        bg_color: "#eee",
        bar_color: "#f16925",
        bar_transition: "all 0.5s ease",
        bar_border_radius: 0,
        spacing: 4,
        text: "",
        text_align: "right",
        text_position: "bottom",
        font_size: 13,
        text_fg_color: "#222",
      }
    };
  },
  methods: {
    startprogressbar() {
      let interval = setInterval(() => {
        if (this.progress_bar.text == '') {
          this.progress_bar.text = 'Searching...';
        }
        this.progress_bar.val += parseInt((Math.random() * 10));
        if (this.progress_bar.val > this.progress_bar.max) {
          this.progress_bar.max += parseInt(this.progress_bar.val / 2);
        }
      }, 150);
      this.progress_bar.intervals.push(interval);
      return interval;
    },
    finishprogressbar() {
      this.progress_bar.intervals.forEach(x => {
        clearInterval(x);
      });
      this.progress_bar.val = 0;
      this.progress_bar.max = 100;
      this.progress_bar.text = '';
    },
    searching() {
      debounce.__(() => {
        if (this.search_query != '') {
          let interval = this.startprogressbar();
          this.$store.dispatch("search/query", {query: this.search_query}).then((response) => {
            this.finishprogressbar();
            if (interval == this.progress_bar.intervals.pop()) {
              this.search_height = 'height : 700px;'
              this.search_results = response.data.data;
            }
          });
        } else {
          this.finishprogressbar();
          this.progress_bar.intervals.push(setInterval(() => {
            this.progress_bar.val += 10;
            if (this.progress_bar.val > this.progress_bar.max) {
              this.finishprogressbar();
            }
          }, 40))
          this.search_results = {
            contacts: [],
            deals: [],
            leads: [],
            employees: []
          };
          this.search_height = '';
        }
      }, 500)
    },
    fetch() {
      //this.$insProgress.start();
      this.$store.dispatch("notification/fetch").then((response) => {
        //console.log("notifcation",response);
        this.notifications = response.data.data;
        if (this.user.id > 2) {
          let unread = this.notifications.filter((element) => {
            return element.read === 0;
          });
          if (unread.length > 0) {
            this.notification_toggle = true;
          }
        } else {
          let unread = this.notifications.filter((element) => {
            return element.admin_read === 0;
          });
          if (unread.length > 0) {
            this.notification_toggle = true;
          }
        }

        //this.$insProgress.finish();
      }).catch((e) => {
        //this.$insProgress.finish();
      });
    },
    base_url(value) {
      return window.location.origin + '/' + value;
    },
    logout() {
      this.$store.dispatch("api/logout", null);
      this.$cookie.delete('auth');
      this.$router.push('/login');
    },
    clearSearch(){
      this.dashboardSearchModal = false;
      console.log("search close");
      document.getElementById('search_popup_input').value = '';
      this.search_query = '';
      this.search_results = {
        contacts: [],
        deals: [],
        leads: [],
        employees: []
      };
      this.search_height = '';
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    focus_search() {
      document.getElementById('search_popup_input').focus();
    },
    init() {
      this.channel.sms = this.$pusher.subscribe('NotificationEvent');
      this.channel.sms.bind('message', this.sms_event);

      this.channel.call = this.$pusher.subscribe('NotificationEvent');
      this.channel.call.bind('status', this.call_event);
    },
    sms_event(data) {
      if (this.user.id < 3) {
        this.notifications.unshift(data);
      } else {
        if (data.user_id === this.user.id) {
          this.notifications.unshift(data);
        }
      }
      //console.log({SMS_NOTIFICATION : data});

    },
    call_event(data) {
      //console.log({CALL_NOTIFICATION : data});
    },
    toggle_nav() {
      this.$store.dispatch("notification/mark");
      this.notification_toggle = false;
    },

  },
  created() {
    this.user = this.$store.getters['api/user'];
    this.init();
    this.fetch();
  }
};
</script>

<style scoped>
div#SearchResult {
  padding: 15px 30px;
}

.top-navbar {
  top: 0px;
}

a.closebtn-serch {
  font-size: 30px;
  position: absolute;
  top: 4px;
  right: -6px;
  color: #f16925;
}

.relative-d {
  position: relative;
}

.main-drop-wrap {
  overflow-y: scroll;
  padding: 0 10px 0 0;
}

.fr-mr {
  margin-right: 18px;
}


.allData .employeeDataActive {
  margin-bottom: 18px;
}

.main-drop-wrap::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.main-drop-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.main-drop-wrap::-webkit-scrollbar-thumb {
  background: #f16925;
}

/* Handle on hover */
.main-drop-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 992px) {
  div#SearchResult {
    position: absolute;
    left: 0;
    max-width: 96%;
    padding: 4px;
    margin: 0 auto;
    right: 0;
  }

  .main-drop-wrap[data-v-41552ae3] {
    padding: 10px;
  }

  a.closebtn-serch[data-v-41552ae3] {
    top: 2px;
    right: 2px;
  }

}
</style>
