<template>
  <!-- [dial-pad]-{start} -->
  <div class="top-cal">
    <div class="voice-call-header">
      <div class="cal-dot"></div>
      <div class="voice-cal">
        <h4 class="font-call ">Voice Call</h4>
      </div>
      <div class="voice-txt">
        <span><input type="text" class="text-center" v-model="number.code"></span>
      </div>
    </div>
    <div class="under-cal">
      <div class="main-cal">
        <div class="cal-input">
          <input type="text" v-mask="'(###) ###-####'" v-model="number.text" @keyup="trim()"
                 placeholder="(000) 000-0000"/>
        </div>
        <div class="cal-grid">
          <div class="contain-grid" @click="add('1')">
            <div class="cont">1</div>
            <div class="cont-text"></div>
          </div>
          <div class="contain-grid" @click="add('2')">
            <div class="cont">2</div>
            <div class="cont-text">ABC</div>
          </div>
          <div class="contain-grid" @click="add('3')">
            <div class="cont">3</div>
            <div class="cont-text">>DEF</div>
          </div>
          <div class="contain-grid" @click="add('4')">
            <div class="cont">4</div>
            <div class="cont-text">GHI</div>
          </div>
          <div class="contain-grid" @click="add('5')">
            <div class="cont">5</div>
            <div class="cont-text">JKL</div>
          </div>
          <div class="contain-grid" @click="add('6')">
            <div class="cont">6</div>
            <div class="cont-text">MNO</div>
          </div>
          <div class="contain-grid" @click="add('7')">
            <div class="cont">7</div>
            <div class="cont-text">PQRS</div>
          </div>
          <div class="contain-grid" @click="add('8')">
            <div class="cont">8</div>
            <div class="cont-text">TUV</div>
          </div>
          <div class="contain-grid" @click="add('9')">
            <div class="cont">9</div>
            <div class="cont-text">WXZ</div>
          </div>
          <div class="contain-grid" @click="add('*')">
            <div class="cont">*</div>
            <div class="cont-text"></div>
          </div>
          <div class="contain-grid" @click="add('0')">
            <div class="cont">0</div>
            <div class="cont-text">+</div>
          </div>
          <div class="contain-grid" @click="add('#')">
            <div class="cont-text"></div>
            <div class="cont">#</div>
          </div>
        </div>
        <div class="cal-footer">
          <div class="left-btn">
            <button>
              <svg v-if="state.dialed" version="1.1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" fill="#ffffff"
                   style="enable-background: new 0 0 32 32" xml:space="preserve">
                                <g id="hangup">
                                    <g>
                                        <path
                                          class="st13"
                                          d="M16.4,10.8c3.4,0,6.8,0.7,9.6,2c1.7,0.8,3.9,2.1,3.8,4.1c0,0,0,0.1,0,0.1l0,0.8c0,0.6,0,1.2,0,1.8
                                                c0,0,0,0.1,0,0.1c0,0.5-0.1,0.8-0.4,1.1c-0.2,0.2-0.6,0.3-1,0.3l-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-1.3,0.1-2.8,0.1-4.2,0.1
                                                c-0.5,0-0.9-0.1-1.2-0.4c-0.3-0.3-0.4-0.7-0.4-1.2c0,0,0-0.1,0-0.1c-0.1-2.1-1.7-3.8-3.7-4.2c-0.6-0.1-1.3-0.2-1.9-0.2
                                                c-0.3,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.5,0-0.7,0c-2.2,0.1-4.2,1.7-4.8,3.8c-0.1,0.2-0.1,0.4-0.1,0.6
                                                c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.4-1.2,0.4l-4.4,0c-0.9,0-1.5-0.6-1.5-1.5l0-1l0-0.1c0-0.6,0-1.1,0-1.7c0-0.9,0.3-1.6,1-2.3
                                                C3.8,14,4.7,13.4,6,12.8c2.9-1.4,6.4-2.1,10.1-2.1C16.2,10.8,16.3,10.8,16.4,10.8 M16.4,8.8c-0.1,0-0.3,0-0.4,0c0,0,0,0,0,0
                                                c-4,0-7.7,0.8-10.9,2.3c-1.4,0.6-2.5,1.4-3.4,2.2c-1.1,1.1-1.7,2.4-1.6,3.8c0,0.6,0,1.1,0,1.7l0,1c0,2,1.5,3.5,3.5,3.5l4.4,0
                                                c0,0,0,0,0,0c1.3,0,2.1-0.5,2.6-1c0.5-0.5,1-1.3,1-2.6c0.4-1.4,1.6-2.4,3.1-2.5c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0,0.6,0
                                                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0.4,0,1,0,1.6,0.1c1.1,0.2,2,1.2,2.1,2.3l0,0c0,1.3,0.5,2.1,1,2.6
                                                c0.5,0.5,1.3,1,2.6,1c1.5,0,3,0,4.3-0.1c0.1,0,0.1,0,0.2,0c0.9,0,1.8-0.3,2.4-0.9c0.5-0.5,1-1.3,1-2.5c0-0.6,0-1.3,0-1.9l0-0.8
                                                c0.2-3.6-3.7-5.4-5-6C23.8,9.5,20,8.8,16.4,8.8L16.4,8.8z"
                                        />
                                    </g>
                                </g>
                            </svg>
              <svg id="phone" v-if="!state.dialed" @click="conference_start()" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 32 32" fill="#ffffff" style="enable-background: new 0 0 32 32" xml:space="preserve"><path class="cls-1" d="M7.08,2.14h.08a2,2,0,0,1,1.45.74L8.73,3c1.11,1,2.37,2.28,3.58,3.49a2.24,2.24,0,0,1,.74,1.57,2.24,2.24,0,0,1-.74,1.56l-.1.1a4.92,4.92,0,0,0-.35,6.11,12.56,12.56,0,0,0,2,2.27h0a12.25,12.25,0,0,0,1.16,1.16,6,6,0,0,0,4,1.48,6.32,6.32,0,0,0,2.86-.69,2,2,0,0,0,.49-.37,2.24,2.24,0,0,1,1.57-.74,2.21,2.21,0,0,1,1.56.75l3.69,3.69a2,2,0,0,1,0,3l-.8.78-.07.06c-.47.46-.95.92-1.42,1.44a3.94,3.94,0,0,1-3.06,1.18A11.39,11.39,0,0,1,19.93,29,28.49,28.49,0,0,1,9.69,22.26,28,28,0,0,1,3,12.15c-.78-2.16-1.6-5.24.3-7l.1-.09.68-.71c.49-.51,1-1,1.51-1.49l.08-.08a2,2,0,0,1,1.43-.67m0-2A4,4,0,0,0,4.24,1.4c-.56.5-1.09,1-1.62,1.59l-.68.71C-1.17,6.52.53,11.26,1.1,12.83A30.08,30.08,0,0,0,8.3,23.7a30.55,30.55,0,0,0,10.94,7.19,13.29,13.29,0,0,0,4.62,1A6,6,0,0,0,28.39,30c.44-.49.92-.95,1.4-1.42l.82-.79a4,4,0,0,0,0-5.87l-3.7-3.69a4,4,0,0,0-5.95,0,4.17,4.17,0,0,1-1.94.47,4,4,0,0,1-2.65-1,13.06,13.06,0,0,1-1-1,.93.93,0,0,0-.14-.14,11.3,11.3,0,0,1-1.73-1.94,2.93,2.93,0,0,1,.21-3.63v0a4.14,4.14,0,0,0,1.33-3,4.15,4.15,0,0,0-1.33-3c-1.25-1.25-2.49-2.5-3.64-3.55A4,4,0,0,0,7.21.14Z" /></svg>
            </button>
            <p v-if="!state.dialed">Call</p>
            <p v-if="state.dialed">{{ state.status }}</p>
          </div>
          <div class="footer-btn">
            <div class="left-btn">
              <button>
                <svg v-if="state.dialed" @click="conference_end()" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" fill="none"
                     stroke="currentColor" style="enable-background: new 0 0 32 32" xml:space="preserve"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <g id="intercept">
                                        <circle class="st22" cx="10.9" cy="16" r="9.8"/>
                                      <circle class="st22" cx="21.1" cy="16" r="9.8"/>
                                    </g>
                                </svg>
                <svg v-if="!state.dialed" @click="backspace()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"/>
                </svg>
              </button>
              <p v-if="state.dialed">Intercept</p>
              <p v-if="!state.dialed">Del</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="call-Log-main">
      <h2>
        <svg class="circle-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.955 31.955"
             style="enable-background: new 0 0 31.955 31.955" xml:space="preserve">
                    <g>
                        <path style="fill: #f16925"
                              d="M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3   c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z"/>
                      <path style="fill: #f16925"
                            d="M13.288,23.896l-1.768,5.207c2.567,0.829,5.331,0.886,7.926,0.17l-0.665-5.416   C17.01,24.487,15.067,24.5,13.288,23.896z M8.12,13.122l-5.645-0.859c-0.741,2.666-0.666,5.514,0.225,8.143l5.491-1.375   C7.452,17.138,7.426,15.029,8.12,13.122z M28.763,11.333l-4.965,1.675c0.798,2.106,0.716,4.468-0.247,6.522l5.351,0.672   C29.827,17.319,29.78,14.193,28.763,11.333z M11.394,2.883l1.018,5.528c2.027-0.954,4.356-1.05,6.442-0.288l1.583-5.137   C17.523,1.94,14.328,1.906,11.394,2.883z"/>
                      <circle style="fill: #f16925" cx="15.979" cy="15.977" r="6.117"/>
                    </g>
                </svg>
        <p>Call Log</p>
      </h2>
      <div class="notifications-section" style="height: 275px">
        <div class="call-log-detail" v-for="log of logs.collection" :key="log.id">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
               y="0px" viewBox="0 0 32 32" style="enable-background: new 0 0 32 32" xml:space="preserve">
                        <g id="phone_x5F_notreached" class="st0" v-if="false">
                            <path class="st1"
                                  d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
                          <line class="st1" x1="31" y1="1" x2="19.2" y2="12.8"/>
                          <line class="st1" x1="19.2" y1="1" x2="31" y2="12.8"/>
                        </g>
            <g id="phone_x5F_incoming" class="st0" v-if="log.direction === 'inbound'">
                            <path class="st1"
                                  d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
              <g class="st3">
                                <polyline class="st4" points="24,1 19.2,5.8 24,10.5   "/>
                <line class="st4" x1="19.2" y1="5.8" x2="31" y2="5.8"/>
                            </g>
                        </g>
            <g id="phone_x5F_outgoing" v-if="log.direction === 'outbound'">
                            <path class="st4"
                                  d="M18.6,19.5c0.9-0.9,1.9-1.1,2.5-1.1c0.6,0,1.6,0.2,2.5,1.1l3.1,3.1c1.4,1.4,1.4,3.5,0,4.9L26,28.2   c-0.4,0.4-0.8,0.8-1.2,1.2c-1,1-2.2,1.5-3.8,1.5c-1.1,0-2.4-0.3-3.9-0.8c-3.2-1.2-6.4-3.3-9.2-6c-2.7-2.6-4.8-5.8-6-9.1   c-0.5-1.3-1.9-5.3,0.7-7.7l0.6-0.6c0.4-0.5,0.9-0.9,1.4-1.3c0.9-0.9,1.8-1.1,2.5-1.1c0.9,0,1.7,0.4,2.4,1.2c1,0.9,2,1.9,3.1,3   c0.9,0.9,1.1,1.9,1.1,2.5c0,0.6-0.2,1.6-1.1,2.5l0,0c-0.8,0.9-0.8,2.1-0.2,3c0.6,0.8,1.2,1.4,1.5,1.6c0,0,0.1,0.1,0.1,0.1   c0.2,0.2,0.4,0.5,0.8,0.8C15.8,20,17.4,20.1,18.6,19.5L18.6,19.5z"/>
              <g>
                                <polyline class="st4" points="26.2,1 31,5.8 26.2,10.5   "/>
                <line class="st4" x1="31" y1="5.8" x2="19.2" y2="5.8"/>
                            </g>
                        </g>
                    </svg>
          <div class="call-Log-content">
            <p>
              <span class="call-log-status">{{
                  log.direction === "outbound" ? "Outgoing Call" : "Incoming Call"
                }}</span> :


              <span v-if=" splitter(log.parent_type) === 'Deal' ">
                <router-link :to="{ name :'View Deal', params:{id:log.parent.id}}">
                  <span class="call-log-name">
                    {{ log.parent ? log.parent.TITLE : log.participant }}
                  </span>
                </router-link>
              </span>

              <span v-if=" splitter(log.parent_type) === 'Lead' ">
                <router-link :to="{ name :'View Lead', params:{id:log.parent.id}}">
                  <span class="call-log-name">
                    {{ log.parent ? log.parent.TITLE : log.participant }}
                  </span>
                </router-link>
              </span>

            </p>
            <p class="time-details">
              <span>{{ date_format(log.updated_at) }} </span>
            </p>
          </div>
        </div>
        <infinite-loading :identifier="logs.identifier" direction="bottom"
                          @infinite="infiniteHandler"></infinite-loading>
      </div>
    </section>
  </div>
  <!-- [dial-pad]-{end} -->
</template>
<script>
import {functor} from 'd3';
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "dialpad",
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      number: {
        text: '',
        trim: '',
        code: '+1',
      },
      state: {
        dialed: false,
        status: 'CALLING',
        object: {}
      },
      user: {},
      channel: {},
      logs: {
        collection: [],
        page: 1,
        identifier: +new Date(),
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.user = this.$store.getters["api/user"];
      this.channel = this.$pusher.subscribe("RawCallStatus");
      this.channel.bind("status", this.status);
    },
    trim() {
      let numbers = this.number.text.match(/\d/g);
      if (numbers) {
        if (numbers.length > 0) {
          this.number.trim = numbers.join('');
        } else {
          this.number.trim = '';
        }
      } else {
        this.number.trim = '';
      }
    },
    status(data) {
      //console.log(data);
      if (data.call.id === this.state.object.id) {
        let twillio_verbs = [
          {
            status: 'canceled',
            show: '[ canceled ]'
          },
          {
            status: 'failed',
            show: '[ failed ]'
          },
          {
            status: 'no-answer',
            show: '[ no-answer ]'
          },
          {
            status: 'busy',
            show: '[ busy ]'
          },
          {
            status: 'answered',
            show: '[ answered ]'
          },
          {
            status: 'completed',
            show: '[ completed ]'
          }
        ];
        let twillio_status = twillio_verbs.find(t => t.status === data.call.status);
        if (twillio_status !== undefined) {
          this.state.status = twillio_status.status;
          setTimeout(this.reboot, 1200);
        } else {
          this.state.status = "[ " + data.call.status.toString() + " ]";
        }
      }
    },
    conference_start() {
      if (this.number.trim.length === 10) {
        this.toggle_state('dialed');
        this.$store.dispatch("call/raw_call", {phone: (this.number.code + this.number.trim)}).then((response) => {
          console.log("call/raw_call", response);
          this.state.object = response.data.data;
        })
      }
    },
    reboot() {
      this.toggle_state('dialed');
      this.state.object = {};
    },
    conference_end() {
      if (this.state.object.id !== undefined) {
        this.$store.dispatch("call/raw_hangup", {id: this.state.object.id}).then((response) => {
          console.log("call/raw_hangup", response);
          this.state.object = {};
          this.toggle_state('dialed');
        });
      }
    },
    fetch() {
      this.$insProgress.start();
      this.$store.dispatch("call/logs", {page: this.logs.page}).then((response) => {
        let logs = response.data.data.data;
        if (logs.length > 0) {
          this.logs.collection.push(...logs);
          this.logs.page++;
          this.logs.identifier = +new Date();
        }
        this.$insProgress.finish();
      });
    },
    add(number) {
      this.number.text += number;
      this.trim();
    },
    backspace() {
      this.number.text = this.number.text.slice(0, this.number.text.length - 1);
      this.trim();
    },
    toggle_state(name) {
      this.state[name] = !this.state[name];
    },
    infiniteHandler($state) {
      this.fetch();
      return true;
    },
    splitter(type) {
      return type.split("\\").pop();
    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
  },
};
</script>
<style scoped>
.voice-txt {
  width: 50%;
}

.voice-cal {
  width: 50%;
}

.voice-call-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.top-cal {
  padding-top: 0rem;
  padding-bottom: 0rem;
  position: relative;
  min-height: auto;
  overflow: hidden;
  justify-content: center;
  display: block;
  flex-direction: column;
}

.top-cal h4.font-call {
  color: #bbbcc4;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.voice-call-header input {
  border: 1px dashed #124678;
  width: 40%;
  float: right;
}

.voice-call-header input:focus {
  outline: none;
}

.under-cal {
  max-width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%); */
  position: relative;
}

.main-cal {
  padding-top: 6px;
  padding-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.cal-input {
  border-radius: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.cal-input input {
  background-color: #b4f2ff;
  color: #2a4365;
  letter-spacing: 0.1em;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  border-radius: 0.5rem;
  border-color: transparent;
  width: 100%;
  border: none !important;
  outline: none;
}

.cal-input input:focus {
  border: none;
  border-color: transparent !important;
}

.cal-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  display: grid;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.cal-grid .contain-grid {
  cursor: pointer;
  border-width: 1px;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  font-size: 1.25rem;
  text-align: center;
}

.cal-grid .contain-grid .cont {
  padding-top: 0.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  margin: 0;
}

.cal-grid .contain-grid .cont-text {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
}

.cal-grid .contain-grid:hover {
  background-color: #ed8936;
  color: #fff;
}

.cal-footer {
  padding-left: 0rem;
  padding-right: 0rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem;
  text-align: center;
  font-size: 1.5rem;
  display: grid;
}

.cal-footer .left-btn {
  grid-column: span 2 / span 2;
}

.cal-footer .left-btn button svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}

.cal-footer .left-btn button {
  background-color: #ed8936;
  border-radius: 0.375rem;
  width: 100%;
  border: none;
  padding: 11px;
  cursor: pointer;
}

.cal-footer .left-btn p {
  color: #a9aec0;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.cal-footer .left-btn:last-child button {
  background-color: #edf2f7;
}

.cal-footer .left-btn:last-child svg {
  color: #a0aec0;
}

.font-set {
  margin: 0;
  color: #b5b6bf;
}

.cal-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f16925;
  float: left;
  margin: 0;
}

.call-Log-main .circle-svg {
  width: 6px;
  height: 6px;
}

.call-Log-main h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.call-Log-main h2 p {
  color: #bbbcc4;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.call-Log-main .call-log-detail {
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
  margin: 10px 0px;
}

.call-Log-main .call-log-detail svg {
  width: 28px;
  height: 28px;
}

.call-Log-main .time-details {
  color: #a0a0a0;
  font-size: 11px;
}

.call-log-name {
  color: #ed8936;
}

.call-log-status {
  color: #124678;
}

.call-Log-content p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.call-log-detail .st1 {
  display: inline;
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.call-log-detail .st4 {
  fill: none;
  stroke: #ed8936;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
