import filters from "./filters/index.js";
import directives from "./directives/index.js";
import plugins from "./plugins/index.js";
import components from "./components/index.js";
//import providers from "./providers/index";

const installer = [
    filters,
    directives,
    plugins,
    components
];

export default {
    install: (Vue) => {
        (process.env.VUE_APP_DEBUG == 'true' ? console.log(":: INSTALLING ADDONS ::") : "");
        for (const iterator of installer) {
            iterator.install(Vue);
        }
        (process.env.VUE_APP_DEBUG == 'true' ? console.log(":: ADDONS INSTALLED ::") : "");
    }
};