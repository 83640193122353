//load all filters that is being registered
import registery from "./register";

export default {
    install: (Vue) => {
        (process.env.VUE_APP_DEBUG == 'true' ? console.log(":: REGISTERING GLOBAL FILTERS :: [") : '');
        for (const iterator of registery) {
            Vue.filter(iterator.name, iterator.fn);
            (process.env.VUE_APP_DEBUG == 'true' ? console.log("REGISTERED FILTER :: ", iterator.name) : '');
        }
        (process.env.VUE_APP_DEBUG == 'true' ? console.log("]") : '');
    }
};