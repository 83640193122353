<template>
  <div>
    <div class="notify-dot">
      <h4><i class="fa fa-circle" aria-hidden="true"></i> Notifications</h4>
    </div>
    <div class="notifications-section">
      <div class="note-section" v-for="notification of notifications" :key="notification.id">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
          </svg>
        </div>
        <div>
          <p>
            {{ splitter(notification.object_type) }}
            <span>
              <span v-if=" splitter(notification.object_type) === 'Deal' ">
                <router-link :to="{ name :'View Deal', params:{id:notification.object.id}}">
                  <span class="call-log-name">
                    {{ notification.object.TITLE }}
                  </span>
                </router-link>
              </span>

              <span v-if=" splitter(notification.object_type) === 'Lead' ">
                <router-link :to="{ name :'View Lead', params:{id:notification.object.id}}">
                  <span class="call-log-name">
                    {{ notification.object.TITLE }}
                  </span>
                </router-link>
              </span>

            </span>
            has replied:
            {{ notification.parent.text }}
          </p>
          <time style="float: right; padding-top: 5px; padding-right: 10px">{{
              date_format(notification.created_at)
            }}
          </time>
        </div>
        <div class="mini-img">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
        </div>
      </div>
      <infinite-loading :identifier="infiniteId" direction="bottom" @infinite="infiniteHandler"></infinite-loading>
    </div>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "system-notifications",
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      notifications: [],
      page: 1,
      infiniteId: +new Date(),
    };
  },
  methods: {
    fetchNotification() {
      this.$insProgress.start();
      this.$store.dispatch("notification/fetch", this.page).then((response) => {
        let fetchedNotifications = response.data.data.data;
        console.log(fetchedNotifications);
        if (fetchedNotifications.length > 0) {
          // if(this.user.id > 2){
          //     let unread = fetchedNotifications.filter((element)=>{
          //         return element.read === 0;
          //     });
          // }else{
          //     let unread = fetchedNotifications.filter((element)=>{
          //         return element.admin_read === 0;
          //     });
          // }
          this.notifications.push(...fetchedNotifications);
          console.log(this.notifications);
          this.page++;
          this.infiniteId = +new Date();
        } else {
        }
        this.$insProgress.finish();
      }).catch((e) => {
        this.$insProgress.finish();
      });
    },
    infiniteHandler($state) {
      this.fetchNotification();
      return true;

    },
    date_format(date_string) {
      const date = new Date(date_string);
      return date.toLocaleTimeString("en-US") + " " + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1).toString() : date.getDate() + 1) + "-" + date.getFullYear();
    },
    splitter(type) {
      return type.split("\\").pop();
    },
  },
};
</script>
<style scoped>
.notifications-section {
  width: 100%;
  float: left;
  clear: both;
  margin-top: 10px;
  padding-top: 8px;
}

.notify-dot h4 {
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  padding-left: 14px;
  color: #bbbcc4;
  font-weight: 500;
  font-size: 16px;
}

.notify-dot h4 i.fa.fa-circle {
  font-size: 8px;
  position: absolute;
  left: 0;
  top: 8px;
  color: #f16925;
}

.notifications-section h4 {
  color: #bbbcc4;
  font-weight: 500;
  font-size: 15px;
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-bottom: 5px;
}

.notifications-section h4 i {
  color: #f16925;
  font-size: 9px;
  margin-right: 9px;
}

.note-section {
  width: 100%;
  float: left;
  clear: both;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 12px;
  display: grid;
  grid-template-columns: 11% 89%;
  grid-gap: 10px;
  margin-bottom: 10px;
  position: relative;
}

.note-section p {
  font-size: 11.5px;
  padding-right: 12px;
  color: #124678;
  font-weight: 600;
  margin-bottom: 0;
}

.note-section svg {
  color: #f16925;
}

.note-section p span {
  color: #f16925;
  font-weight: 500;
}

.note-section time {
  color: #a1a3ae;
  font-weight: 500;
  font-size: 11px;
}

.note-section .mini-img {
  position: absolute;
  top: -12px;
  right: 0;
  width: 18px;
  z-index: 999;
  cursor: pointer;
}

.note-section .mini-img svg {
  color: #a1a3ae;
}
</style>
