//return first-letters of words in the given string
const initials = {
    install(Vue, options) {
        Vue.prototype.initials = (name) => {
            let initial = "";
            if (name) {
                name.split(" ").forEach((word) => {
                    initial += word[0];
                });
            }
            return (initial.length > 2 ? initial.slice(0, 2) : initial).toUpperCase();
        };
    }
};

const fn = {
    endpoint: () => {
        switch (process.env.NODE_ENV) {
            case 'development':
                return 'http://127.0.0.1:8000/';
                break;

            case 'production':
                return 'https://ttechcode.com/onsite_api/public/';
                break;

            default:
                return 'http://127.0.0.1:8000/';
                break;
        }
    },
    __esc: (value, replacer) => {
        if (fn.is_useless(value)) {
            return replacer;
        }
        return value;
    },
    is_useless: (object) => {
        if (object !== undefined && object !== null) {
            return false;
        }
        return true;
    },
    is_array: (payload) => {
        if (typeof(payload) === 'object') {
            if (!fn.is_useless(payload.length)) {
                return true;
            }
        }
        return false;
    },
    is_object: (payload) => {
        if (typeof(payload) === 'object') {
            return true;
        }
        return false;
    },
    array_first: (payload, fail = false) => {
        if (fn.is_array(payload)) {
            if (!fn.is_useless(payload.length)) {
                if (payload.length > 0) {
                    return payload[0];
                }
            }
        }
        return fail;
    },
    array_last: (payload, fail = false) => {
        if (fn.is_array(payload)) {
            if (!fn.is_useless(payload.length)) {
                if (payload.length > 0) {
                    return payload[(payload.length - 1)];
                }
            }
        }
        return fail;
    },
    array_prev: (payload, current_index, fail = false) => {
        if (fn.is_array(payload)) {
            if (!fn.is_useless(payload.length)) {
                if (current_index - 1 !== -1) {
                    if (!fn.is_useless(payload[current_index - 1])) {
                        return payload[current_index - 1];
                    }
                }
            }
        }
        return fail;
    },
    array_find: (payload, comparison, fail = false) => {
        if (fn.is_array(payload)) {
            if (!fn.is_useless(payload.length)) {
                let needle = payload.find((p) => {
                    if (comparison(p)) {
                        return p;
                    }
                });
                if (!fn.is_useless(needle)) {
                    return needle;
                }
            }
        }
        return fail;
    },
    chain_value: (payload, keys, fail = false) => {
        if (fn.is_array(keys)) {
            let first = fn.array_first(keys);
            if (first) {
                if (!fn.is_useless(payload[first])) {
                    keys.shift();
                    return fn.chain_value(payload[first], keys);
                }
            } else {
                return payload;
            }
        } else {
            if (!fn.is_useless(payload.keys)) {
                return payload.keys;
            }
        }
        return fail;
    }
}

const endpoint = {
    install(Vue, options) {
        Vue.prototype.endpoint = fn.endpoint
    }
}

const is_useless = {
    install(Vue, options) {
        Vue.prototype.is_useless = fn.is_useless
    }
}

const is_array = {
    install(Vue, options) {
        Vue.prototype.is_array = fn.is_array
    }
}

const is_object = {
    install(Vue, options) {
        Vue.prototype.is_object = fn.is_object
    }
}

const array_first = {
    install(Vue, options) {
        Vue.prototype.array_first = fn.array_first
    }
}

const array_last = {
    install(Vue, options) {
        Vue.prototype.array_last = fn.array_last
    }
}

const array_prev = {
    install(Vue, options) {
        Vue.prototype.array_prev = fn.array_prev
    }
}

const array_find = {
    install(Vue, options) {
        Vue.prototype.array_find = fn.array_find
    }
}

const chain_value = {
    install(Vue, options) {
        Vue.prototype.chain_value = fn.chain_value
    }
}

const __esc = {
    install(Vue, options) {
        Vue.prototype.__esc = fn.__esc
    }
}

const global = [
    initials,
    endpoint,
    is_useless,
    is_array,
    is_object,
    array_first,
    array_last,
    array_find,
    array_prev,
    chain_value,
    __esc
];

export {
    initials,
    endpoint,
    is_useless,
    is_array,
    is_object,
    array_first,
    array_last,
    array_find,
    array_prev,
    chain_value,
    __esc
};

export default global;