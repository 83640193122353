import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
  leads: {},
  xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
  init(context, value) {
    if (context.rootState.api.auth.xios !== undefined) {
      context.commit("INIT", context.rootState.api.auth.xios);
      return true;
    } else {
      return false;
    }
  },
  fetch(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('lead/list', value);
    }
  },
  getlead(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('lead/read', data);
    }
  },
  insert(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('lead/create', value);
    }
  },
  delete(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('lead/delete', data);
    }
  },
  update(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('lead/update', value);
    }
  },
  call(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      data.append('parent_type', 'lead');
      return context.state.xios.post('twillio/outbound/call/start', data);
    }
  },
  hangup(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('twillio/outbound/call/end', data);
    }
  },
  conference_start(context, value) {
    if (context.dispatch('init')) {

      var data = new FormData();
      data.append('id', value);
      data.append('parent_type', 'lead');
      // //console.log(data);
      return context.state.xios.post('conference/start', data);
    }
  },
  conference_end(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      // //console.log(data);
      return context.state.xios.post('conference/end', data);
    }
  },
  conversation_read(context, value) {
    if (context.dispatch('init')) {

      var data = new FormData();
      data.append('id', value);
      data.append('parent_type', 'lead');
      return context.state.xios.post('twillio/general/sms/read', data);
    }
  },
  conversation_send(context, value) {
    if (context.dispatch('init')) {
      //console.log(value);
      var data = new FormData();
      data.append('id', value.id);
      data.append('message', value.message);
      data.append('parent_type', 'lead');
      return context.state.xios.post('twillio/outbound/sms/send', data);
    }
  },
  conversation_records(context, value) {
    if (context.dispatch('init')) {
      //console.log(value);
      var data = new FormData();
      data.append('id', value.id);
      data.append('page', value.page);
      data.append('parent_type', 'lead');
      return context.state.xios.post('twillio/outbound/call/all', data);
    }
  },
  conversation_records_delete(context, value) {
    if (context.dispatch('init')) {
      //console.log(value);
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('twillio/outbound/call/delete', data);
    }
  },
  twiilio_number_fetch(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('twiilio_number/fetch');
    }
  },
  sync(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('sync');
    }
  },
  mark(context, value) {
    if (context.dispatch('init')) {
      //console.log(value);
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('lead/mark', data);
    }
  },
  get_last_lead_time(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('lead/last_updated_lead', null);
    }
  },

};

const mutations = {
  INIT(state, payload) {
    state.xios = payload;
  }

};
const getters = {};

const leads = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default leads;
