var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('vue-ins-progress-bar'),_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          img: '../../../img/svg/dashboard.svg',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Deals'),
          img: '../../../img/svg/deals.svg',
          path: '/deals/index'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('leads'),
          img: '../../../img/svg/leads.svg',
          path: '/leads/index'
        }}}),(_vm.user.id < 3)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Agents'),
          img: '../../../img/svg/agents.svg',
          path: '/agents/index'
        }}}):_vm._e(),(_vm.user.id < 3)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Queue'),
          img: '../../../img/svg/queue.svg',
          path: '/queue/index'
        }}}):_vm._e(),(_vm.user.id < 3)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Scheduler'),
          img: '../../../img/svg/scheduler.svg',
          path: '/scheduler/index'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Conversation'),
          img: '../../../img/svg/conversation.svg',
          path: '/message'
        }}}),(_vm.user.id < 3)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('Setting'),
          img: '../../../img/svg/settings.svg',
          path: '/setting/add'
        }}}):_vm._e()]}}])}),_c('div',{staticClass:"main-panel ps"},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },attrs:{"id":"main-mu"},on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1),_c('RightSidebar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }