<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
  <vue-ins-progress-bar></vue-ins-progress-bar>
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            img: '../../../img/svg/dashboard.svg',
            path: '/dashboard'
          }"
        >
        </sidebar-item>
      <sidebar-item
           :link="{
            name: $t('Deals'),
            img: '../../../img/svg/deals.svg',
            path: '/deals/index'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('leads'),
            img: '../../../img/svg/leads.svg',
            path: '/leads/index'
          }"
        ></sidebar-item>

        <sidebar-item v-if="user.id < 3"
          :link="{
            name: $t('Agents'),
            img: '../../../img/svg/agents.svg',
            path: '/agents/index'
          }"
        ></sidebar-item>
        <sidebar-item  v-if="user.id < 3"
          :link="{
            name: $t('Queue'),
            img: '../../../img/svg/queue.svg',
            path: '/queue/index'
          }"
        ></sidebar-item>
        <sidebar-item  v-if="user.id < 3"
          :link="{
            name: $t('Scheduler'),
            img: '../../../img/svg/scheduler.svg',
            path: '/scheduler/index'
          }"
        ></sidebar-item>

      <sidebar-item
          :link="{
            name: $t('Conversation'),
            img: '../../../img/svg/conversation.svg',
            path: '/message'
          }"
        ></sidebar-item>

        <sidebar-item  v-if="user.id < 3"
          :link="{
            name: $t('Setting'),
            img: '../../../img/svg/settings.svg',
            path: '/setting/add'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->

    <div class="main-panel ps" >
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div id="main-mu"
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->

          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <RightSidebar></RightSidebar>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from './SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

import {
    TimeSelect,
    DatePicker,
    Select,
    Option
} from 'element-ui';
import RightSidebar from "../../components/RightSidebar.vue";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    RightSidebar

  },
  data() {

    return {
      sidebarBackground: 'orange' ,//vue|blue|orange|green|red|primary
      user : {},
      channel : {
          sms  : {},
          call : {}
      },
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
        this.$store.dispatch("api/logout", null);
        this.$cookie.delete('auth');
        this.$router.push('/login');
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    sms_event(data) {
      if(this.user.id < 3){
          this.notifications.unshift(data);
      }else{
          if(data.user_id === this.user.id){
              this.notifications.unshift(data);
          }
      }
      //console.log({SMS_NOTIFICATION : data});
    },
    call_event(data) {
      //console.log({CALL_NOTIFICATION : data});
    },
    date_format(date_string){
        const date = new Date(date_string);
        return date.toLocaleTimeString('en-US') + ' ' + (((date.getMonth()+1) < 10 ?('0'+(date.getMonth()+1).toString()) : (date.getMonth()+1) )) +'-'+ (((date.getDate()+1) < 10 ?('0'+(date.getDate()+1).toString()) : (date.getDate()+1) )) +'-'+ date.getFullYear();
    }
  },
  mounted() {
    let docClasses = document.body.classList;
    docClasses.add('white-content');
    this.initScrollbar();
    this.user = this.$store.getters['api/user'];
  },

  created() {
    this.user = this.$store.getters['api/user'];

    this.channel.sms = this.$pusher.subscribe('NotificationEvent');
    this.channel.sms.bind('message', this.sms_event );

    this.channel.call = this.$pusher.subscribe('NotificationEvent');
    this.channel.call.bind('status', this.call_event );
  }
};




</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

</style>
