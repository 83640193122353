//return first-letters of words in the given string
const initials = {
    name: "initials",
    fn: (name) => {
        let initial = "";
        if (name) {
            name.split(" ").forEach((word) => {
                initial += word[0];
            });
        }
        return (initial.length > 2 ? initial.slice(0, 2) : initial).toUpperCase();
    }
};

const date_format = {
  name: "date_format",
  fn: (date_string) => {
    const date = new Date(date_string);
    return (
      date.toLocaleTimeString("en-US") +
      " " +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() + 1 < 10
        ? "0" + (date.getDate() + 1).toString()
        : date.getDate() + 1) +
      "-" +
      date.getFullYear()
    );
  }
}

const global = [initials, date_format];

export { initials, date_format };
export default global;
