import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/User/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Dashboard
const Dashboard = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');


//Leads
const Leads = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Leads/index.vue');
const AddLeads = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Leads/add.vue');
const EditLead = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Leads/edit.vue');
const ViewLead = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Leads/view.vue');
const RecordLead = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Leads/recording.vue');


//Deals
const Deals = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Deals/index.vue');
const AddDeal = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Deals/add.vue');
const EditDeal = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Deals/edit.vue');
const ViewDeal = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Deals/view.vue');
const RecordDeal = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Deals/recording.vue');

//Agents
const Agents = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Agent/index.vue');
const AddAgent = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Agent/add.vue');
const EditAgent = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Agent/edit.vue');
const ViewAgent = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Agent/view.vue');


//Queue
const Queue = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Queue/index.vue');
const AddQueue = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Queue/add.vue');
const EditQueue = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/Queue/edit.vue');

//Schedule
const Scheduler = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/scheduler/index.vue');
const Add_Scheduler = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/scheduler/add.vue');
const EditScheduler = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/scheduler/edit.vue');


// Message
const Message = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/message/index.vue');

const Setting = () =>
  import ( /* webpackChunkName: "dashboard" */ 'src/pages/setting/add.vue');

const ConversationSMS = () =>
  import ('/src/pages/conversation/sms.vue');

const ConversationEmail = () =>
  import ('/src/pages/conversation/email.vue');
// Auth
const User = () =>
  import ( /* webpackChunkName: "pages" */ 'src/pages/User/UserProfile.vue');
const Login = () =>
  import ( /* webpackChunkName: "pages" */ 'src/pages/User/Login.vue');
const Register = () =>
  import ( /* webpackChunkName: "pages" */ 'src/pages/User/Register.vue');
;

let pagesMenu = {
  path: '/',
  component: DashboardLayout,
  name: 'User',
  redirect: '/profile',
  children: [{
    path: 'Profile',
    name: 'User Profile',
    components: {default: User}
  },]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [{
    path: '/login',
    name: 'Login',
    component: Login
  },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
  ]
};

const routes = [{
  path: '/',
  redirect: '/login',
  name: 'Login'
},
  pagesMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      components: {default: Dashboard}
    },
      //Leads
      {
        path: 'leads/index',
        name: 'Leads',
        components: {default: Leads}
      },
      {
        path: 'leads/add',
        name: 'Add Leads',
        components: {default: AddLeads}
      },
      {
        path: 'leads/edit/:id',
        name: 'Edit Lead',
        components: {default: EditLead}
      },
      {
        path: 'leads/view/:id',
        name: 'View Lead',
        components: {default: ViewLead}
      },
      {
        path: 'leads/recording/:id',
        name: 'Record Lead',
        components: {default: RecordLead}
      },
      //Deals
      {
        path: 'deals/index',
        name: 'Deals',
        components: {default: Deals}
      },
      {
        path: 'deals/add',
        name: 'Add Deal',
        components: {default: AddDeal}
      },
      {
        path: 'deals/edit/:id',
        name: 'Edit Deal',
        components: {default: EditDeal}
      },
      {
        path: 'deals/view/:id',
        name: 'View Deal',
        components: {default: ViewDeal}
      },
      {
        path: 'deals/recording/:id',
        name: 'Record Deal',
        components: {default: RecordDeal}
      },
      //Agent
      {
        path: 'agents/index',
        name: 'Agents',
        components: {default: Agents}
      },
      {
        path: 'agents/add',
        name: 'Add Agent',
        components: {default: AddAgent}
      },
      {
        path: 'agents/edit/:id',
        name: 'Edit Agent',
        components: {default: EditAgent}
      },
      {
        path: 'agents/view/:id',
        name: 'View Agent',
        components: {default: ViewAgent}
      },
      //Queue
      {
        path: 'queue/index',
        name: 'Queue',
        components: {default: Queue}
      },
      {
        path: 'queue/add',
        name: 'Add Queue',
        components: {default: AddQueue}
      },
      {
        path: 'queue/edit/:id',
        name: 'Edit Queue',
        components: {default: EditQueue}
      },
      //Schedule
      {
        path: 'scheduler/index',
        name: 'Scheduler',
        components: {default: Scheduler}
      },
      {
        path: 'scheduler/add',
        name: 'Add_Scheduler',
        components: {default: Add_Scheduler}
      },
      {
        path: 'scheduler/edit/:id',
        name: 'Edit Scheduler',
        components: {default: EditScheduler}
      },
      //Message

      {
        path: 'message/:type/:id',
        name: 'Message',
        components: {default: Message}
      },
      {
        path: 'message',
        name: 'Message',
        components: {default: ConversationSMS}
      },

      {
        path: 'setting/add',
        name: 'Setting',
        components: {default: Setting}
      },
      {
        path: 'conversation/sms',
        name: 'ConversationSMS',
        components: {default: ConversationSMS}
      },
      {
        path: 'conversation/email',
        name: 'ConversationEmail',
        components: {default: ConversationEmail}
      },
    ]
  },
  {path: '*', component: NotFound}
];

export default routes;
