import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const endpoint = "https://api.mforce247.com/";

//default storage of module 'api'
const state = {
    auth: {
        base_endpoint: endpoint,
        token: '',
        name: 'Authorization',
        type: 'Bearer ',
        status: false,
        headers: {
            'Authorization': '',
            'Access-Control-Allow-Credentials': true,
            'Accept': 'application/json'
        },
        user: {},
        xios: {} //this is the copy of axios but have pre-built headers with token use this as axios
    }
};

const actions = {
    async authin(context, value) {
        if (!context.state.auth.status) {
            let response = await axios.post(context.state.auth.base_endpoint + "authin", value);
            if (response.data.code === 200) {
                context.commit("SET_AUTH", response.data);
                return true;
            } else {
                return response;
            }
        }
    },
    async authup(context, value) {
        if (!context.state.auth.status) {
            let response = await axios.post(context.state.auth.base_endpoint + "authup", value);
            if (response.data.code === 200) {
                context.commit("SET_AUTH", response.data);
                return true;
            } else {
                return response;
            }
        }
    },
    cookie_auth(context, value) {
        context.commit("COOKIE_AUTH", value);
    },
    logout(context, value) {
        context.commit("LOGOUT", value);
    }
};

const mutations = {
    SET_AUTH(state, payload) {
        state.auth.status = true;
        state.auth.token = payload.data.token;
        state.auth.headers['Authorization'] = state.auth.type + ' ' + state.auth.token;
        state.auth.xios = axios.create({
            baseURL: state.auth.base_endpoint,
            withCredentials: true,
            headers: state.auth.headers
        });
        //console.log(payload.data.user);
        state.auth.user = payload.data.user;
    },
    COOKIE_AUTH(state, payload) {
        state.auth = payload;
        state.auth.xios = axios.create({
            baseURL: state.auth.base_endpoint,
            withCredentials: true,
            headers: state.auth.headers
        });
    },
    LOGOUT(state, payload) {
        state.auth = {
            base_endpoint: endpoint,
            token: '',
            name: 'Authorization',
            type: 'Bearer ',
            status: false,
            headers: {
                'Authorization': '',
                'Access-Control-Allow-Credentials': true,
                'Accept': 'application/json'
            },
            user: {},
            xios: {} //this is the copy of axios but have pre-built headers with token use this as axios
        };
    }

};
const getters = {
    auth(state) {
        return state.auth;
    },
    user(state) {
        return state.auth.user;
    }
};

const api = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default api;
