<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <a href="#" class="simple-text logo-mini">
          <!--<img :src="logo" alt="app-logo" />-->
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 128.5 39.4;" xml:space="preserve">          
            <g id="logo">
              <path class="st0" d="M6.3,14.9h5.6v2.5h0.1c0.8-1.4,2.7-3,5.5-3c2.6,0,4.8,1.1,5.8,3.4c1.4-2.4,3.2-3.4,5.9-3.4   c5.1,0,6.7,3.6,6.7,8.2v11.1H30v-11c0-1.7-0.7-3.2-2.5-3.2c-2.7,0-3.5,1.9-3.5,4.2v10h-5.8v-9.9c0-1.4,0.1-4.2-2.6-4.2   c-3,0-3.5,2.3-3.5,4.8v9.4H6.3V14.9z"/>
              
            </g>
          </svg>
        </a>
        <a href="#" class="simple-text logo-normal">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 300 40" style="enable-background:new 0 0 128.5 39.4;" xml:space="preserve">
          <g id="logo">
            <path class="st0" d="M6.3,14.9h5.6v2.5h0.1c0.8-1.4,2.7-3,5.5-3c2.6,0,4.8,1.1,5.8,3.4c1.4-2.4,3.2-3.4,5.9-3.4   c5.1,0,6.7,3.6,6.7,8.2v11.1H30v-11c0-1.7-0.7-3.2-2.5-3.2c-2.7,0-3.5,1.9-3.5,4.2v10h-5.8v-9.9c0-1.4,0.1-4.2-2.6-4.2   c-3,0-3.5,2.3-3.5,4.8v9.4H6.3V14.9z"/>
            <path class="st1" d="M40,4.5h5.8v12.4h0.1c1.3-1.8,3.6-2.5,5.9-2.5c5.3,0,8.6,4.8,8.6,10c0,5.7-3.6,9.7-9.2,9.7   c-2.6,0-4.9-1.6-5.8-3h-0.1v2.5H40L40,4.5z M50,29.1c2.9,0,4.6-1.9,4.6-4.7s-1.7-4.7-4.6-4.7c-2.9,0-4.6,1.9-4.6,4.7   C45.3,27.2,47.1,29.1,50,29.1z"/>
            <path class="st1" d="M75.2,31.4L75.2,31.4c-1.4,2-3.6,2.8-6,2.8c-3.5,0-6.7-2-6.7-5.8c0-6.5,7.9-6.6,12.7-6.6   c0-2.1-1.7-3.4-3.7-3.4c-1.9,0-3.5,0.8-4.7,2.3l-3.1-3.2c2.1-2,5.2-3,8.2-3c6.7,0,8.6,3.4,8.6,9.7v9.6h-5.4V31.4z M73.8,25.5   c-1.8,0-5.7,0.1-5.7,2.7c0,1.4,1.4,2.1,2.8,2.1c2.3,0,4.4-1.2,4.4-3.5v-1.2L73.8,25.5z"/>
            <path class="st1" d="M83.8,14.9h5.6v2.5h0.1c0.8-1.4,2.7-3,5.5-3c5.8,0,6.9,3.9,6.9,8.9v10.4h-5.8v-9.3c0-2.2-0.1-5-3-5   c-3,0-3.5,2.3-3.5,4.8v9.3h-5.8L83.8,14.9z"/>
            <path class="st1" d="M118.3,21c-0.5-0.8-1.6-1.4-2.9-1.4c-2.9,0-4.6,1.9-4.6,4.7s1.7,4.7,4.6,4.7c1.3,0,2.2-0.7,2.9-1.4l3.9,4.1   c-1.8,1.9-4.5,2.5-6.7,2.5c-5.8,0-10.4-3.8-10.4-9.9s4.6-9.9,10.4-9.9c2.2,0,4.9,0.7,6.7,2.5L118.3,21z"/>
          </g>
          </svg>      
        </a>
      </div>
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'telephony',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'mb',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: window.location.origin + '/img/logo.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'orange',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar .logo a.logo-mini img, .off-canvas-sidebar .logo a.logo-mini img{
  background: transparent !important;
      border-radius: 0;
}
a.simple-text.logo-normal{
  margin-top:5px;
}
</style>
