import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    auth : {},
};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth);
            return true;
        } else {
            return false;
        }
    },
    fetch(context, value) {
        if (context.dispatch('init')) {
            return context.state.auth.xios.get('user', null);
        }
    },
    update(context, value) {
        if (context.dispatch('init')) {
            //console.log(value);
            var data = new FormData();
            for (var key in value) {
                //console.log(key);
                data.append(key, value[key]);
            }
            //console.log(data);
            context.state.auth.user.phone = value.phone;
            context.commit("UPDATE", value);
            return context.state.auth.xios.post('user/update', data);
        }
    },
    cookie_auth(context, value) {
        context.commit("COOKIE_AUTH", value);
    }
};

const mutations = {
    INIT(state, payload) {
        state.auth = payload;
    },
    UPDATE(state, payload) {
        //console.log("MUTATION UPDATE CALLED");
        //console.log(payload);
        state.auth.user = payload;
    },
    COOKIE_AUTH(state, payload) {
        state.auth.user = payload;
    }

};
const getters = {
    user(state) {
        return state.auth.user;
    }
};

const user = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default user;