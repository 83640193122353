import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
  combined_notification: {},
  xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
  init(context, value) {
    if (context.rootState.api.auth.xios !== undefined) {
      context.commit("INIT", context.rootState.api.auth.xios);
      return true;
    } else {
      return false;
    }
  },
  get_combined(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('notification/combined_list', value);
    }
  },
  get_calls(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('notification/get_call_list', value);
    }
  },
  get_messages(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('notification/get_messages_list', value);
    }
  },
  get_notes_list(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('notification/get_notes_list', value);
    }
  },
  get_stages_list(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('notification/get_stages_list', value);
    }
  },
};

const mutations = {
  INIT(state, payload) {
    state.xios = payload;
  }
};
const getters = {};

const combined_notification = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default combined_notification;
