import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import addons from './addons';

//import VueSocialauth from 'vue-social-auth';
import VueCookie from 'vue-cookie';
// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker';
import store from './store/index';
import Vuex from "vuex";
import VueInsProgressBar from 'vue-ins-progress-bar'
import Chat from 'vue-beautiful-chat'
import * as VueSpinnersCss from "vue-spinners-css";
import InfiniteLoading from 'vue-infinite-loading';
import Multiselect from 'vue-multiselect';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhoneSlash as fasPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { faPhone as fasPhone } from '@fortawesome/free-solid-svg-icons';
import { faPhoneVolume as fasPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountUp as faSortAmountUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown as faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountDown as faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountUpAlt as faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons';
import { faSortAmountDownAlt as faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt as fasCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch as fasCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// Or only as a filter-only
import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)

const options = {
    position: 'fixed',
    show: true,
    height: '7px'
}

import ProgressBar from 'vue-simple-progress'
Vue.component('progress-bar', ProgressBar);

const Pusher = require('pusher-js');
Pusher.logToConsole = true;//(process.env.VUE_APP_DEBUG == 'true' ? true : false);
Vue.prototype.$pusher = new Pusher('dfab9d4248c1b93a3864', {
    cluster: 'ap2'
});

// plugin setup
library.add(fasPhoneSlash, fasPhone, fasPhoneVolume, faSortAmountDown, faSortAmountUp, faSortAmountUpAlt, fasCircleNotch, faSortAmountDownAlt, fasCommentAlt, faAngleDown)
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueAxios, axios);
Vue.use(VueCookie);
Vue.use(Vuex);
Vue.use(VueInsProgressBar, options);
Vue.use(Chat);
Vue.use(VueSpinnersCss);
Vue.use(InfiniteLoading, options);
Vue.component('multiselect', Multiselect);

// /* eslint-disable no-new */

Vue.use(addons);

const app = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
    i18n
});


//cookie-auth
let auth_string = app.$cookie.get("auth");
if (auth_string !== null) {
    var auth = JSON.parse(auth_string);
    //console.log(auth);
    if (auth.status) {
        app.$store.dispatch("api/cookie_auth", Object(auth));
        app.$store.dispatch("user/cookie_auth", Object(auth).user);
        var current_path = document.querySelector('meta[name="current_path"]');
        var content = current_path.getAttribute('content');
        if (content !== '') {
            console.log('content', content);
            if (content === '/login' || content === '/') {
                // app.$router.push('/dashboard');
            } else {
                app.$router.push(content);
            }
        } else {
            // app.$router.push('/dashboard');
        }
    } else {
        app.$router.push('/login');
    }
} else {
    app.$router.push('/login');
}
