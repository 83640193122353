import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    agents: {},
    xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth.xios);
            return true;
        } else {
            return false;
        }
    },
    fetch(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('agent/list', value);
        }
    },
    insert(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('agent/create', value);
        }
    },
    delete(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            return context.state.xios.post('agent/delete', data);
        }
    },
    getagent(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            return context.state.xios.post('agent/read', data);
        }
    },
    update(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('agent/update', value);
        }
    },


};

const mutations = {
    INIT(state, payload) {
        state.xios = payload;
    }

};
const getters = {

};

const agents = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default agents;