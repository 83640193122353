import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';
import base64  from 'base64-js';

Vue.use(VueAxios, axios);

const api_key = 'AIzaSyBpTNkBQvd04slotbEE0CD_fbTsbHmaZXg';
const endpoint = `https://speech.googleapis.com/v1/speech:recognize?key=${api_key}`;

//default storage of module 'api'
const state = {
    base : {
        endpoint: endpoint,
        headers: {
            'Content-Type' : 'application/json'
        },
        xios: {} //this is the copy of axios but have pre-built headers with token use this as axios
    }
};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth);
            return true;
        } else {
            return false;
        }
    },
    async transcribe(context, value) {
        if (context.dispatch('init')) {
            const payload = JSON.stringify({
                config: {
                    encoding: 'LINEAR16',
                    sampleRateHertz: 16000,
                    languageCode: 'en-US'
                },
                audio: {
                    content: base64.fromByteArray(value)
                }
            });

            let response = await context.state.base.xios.post(context.state.base.endpoint,payload);
            if (response.data.code === 200) {
                return response;
            } else {
                return response;
            }
        }
    }
};

const mutations = {
    INIT(state, payload) {
        state.base.xios = axios.create({
            baseURL: state.base.endpoint,
            headers: state.base.headers
        });
    },
};
const getters = {
    
};

const transcribe = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default transcribe;