import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
  general: {},
  xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
  init(context, value) {
    if (context.rootState.api.auth.xios !== undefined) {
      context.commit("INIT", context.rootState.api.auth.xios);
      return true;
    } else {
      return false;
    }
  },
  fetch(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('dashboard/counters', null);
    }
  },
  fetch_status(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('status/all', null);
    }
  },
  insert(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('scheduler/create', value);
    }
  },
  getscheduler(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('scheduler/find', data);
    }
  },
  update(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('scheduler/update', value);
    }
  },
  fetchschedule(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      if (value !== null) {
        data.append('page', value);
      }
      //console.log("dsfsdfsdfsdfsdf");
      return context.state.xios.get('scheduler/all', data);
    }
  },
  delete(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('scheduler/delete', data);
    }
  },
  twillio_numbers(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('twiilio_number/all', null);
    }
  }


};

const mutations = {
  INIT(state, payload) {
    state.xios = payload;
  }

};
const getters = {};

const general = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default general;
