import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    auth: {},
    status: false,
    query: '',
    results: {},
};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth);
            return true;
        } else {
            return false;
        }
    },
    query(context, value) {
        if (context.dispatch('init')) {
            return context.state.auth.xios.post('search', value);
        }
    },
};

const mutations = {
    INIT(state, payload) {
        state.auth = payload;
    },
};
const getters = {
    user(state) {
        return state.auth.user;
    }
};

const search = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default search;
