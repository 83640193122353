import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
  queue: {},
  xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
  init(context, value) {
    if (context.rootState.api.auth.xios !== undefined) {
      context.commit("INIT", context.rootState.api.auth.xios);
      return true;
    } else {
      return false;
    }
  },
  fetch_all_agent(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('agent/all', null);
    }
  },
  fetch_twillio_nbr(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.get('twiilio_number/fetch', null);
    }
  },
  insert(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('queue/create', value);
    }
  },
  update(context, value) {
    if (context.dispatch('init')) {
      return context.state.xios.post('queue/update', value);
    }
  },
  fetch(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      if (value !== null) {
        data.append('page', value);
      }
      return context.state.xios.get('queue/all', data);
    }
  },
  delete(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('queue/delete', data);
    }
  },
  getQue(context, value) {
    if (context.dispatch('init')) {
      var data = new FormData();
      data.append('id', value);
      return context.state.xios.post('queue/get_que', data);
    }
  },


};

const mutations = {
  INIT(state, payload) {
    state.xios = payload;
  }

};
const getters = {};

const queue = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default queue;
