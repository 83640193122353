import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    calls: {},
    xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth.xios);
            return true;
        } else {
            return false;
        }
    },
    logs(context, value){
      if (context.dispatch('init')) {
        return context.state.xios.post('call/logs', value);
      }
    },
    fetch(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('call/list', value);
        }
    },
    insert(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('call/create', value);
        }
    },
    delete(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            return context.state.xios.post('call/delete', data);
        }
    },
    getcall(context, value) {
        if (context.dispatch('init')) {
            var data = new FormData();
            data.append('id', value);
            return context.state.xios.post('call/read', data);
        }
    },
    raw_call(context, value){
      if (context.dispatch('init')) {
        return context.state.xios.post('twillio/outbound/raw_call/start', value);
      }
    },
    raw_hangup(context, value){
      if (context.dispatch('init')) {
        return context.state.xios.post('twillio/outbound/raw_call/end', value);
      }
    },
    update(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('call/update', value);
        }
    },


};

const mutations = {
    INIT(state, payload) {
        state.xios = payload;
    }

};
const getters = {

};

const call = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default call;
