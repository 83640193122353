<template>
  <div class="main-panel-right">
    <div class="right-inner">
      <div class="right-inner-user relative-dp">
        <img src="../../public/img/mike.jpg" v-on:click="profiledb = !profiledb"/>
        <div class="" v-on:click="profiledb = !profiledb">
          <h3>{{ user.name }}</h3>
          <p class="user-spec">{{ user.email }}</p>
        </div>
        <div class="nav-bar-drop" v-if="profiledb">
          <div class="inner-br-dp">
            <ul>
              <li><router-link to="/profile">Profile Settings</router-link></li>
              <li class="flex-in"><a href="#">Administration</a>
                <div>
                  <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                  </label>
                </div>
              </li>
              <li><a href="#">Calender</a></li>
              <li><a href="#">Defaults</a></li>
              <li><a href="#">Configure Menu</a></li>
              <li class="br-pad-none"><a @click="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="user-utilities">
        <!-- class="border-t" -->
        <div class="b-nth-child" @click="toggleTabs('notification')"
             :style="(activeTab('notification') ? 'border: 1px solid #f57236;': '')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
               y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <g id="right-panel-notification">
              <path class="st4"
                    d="M27.3,26.7c1.7,0,2.6-2.3,1.5-3.7c-2.9-3.5-2.6-5.2-2.6-12c0-5.5-4.9-7.6-7.6-8.2c-0.9-0.2-1.8-0.3-2.7-0.3h0   c-0.9,0-1.8,0.1-2.7,0.3C10.5,3.5,5.6,5.5,5.6,11c0,6.7,0.3,8.5-2.6,12c-1.2,1.4-0.3,3.7,1.5,3.7H27.3z"/>
              <path class="st4" d="M12.3,27.5c0,1.9,1.6,3.5,3.6,3.5s3.6-1.5,3.6-3.5"/>
              <line class="st4" x1="15.9" y1="2.5" x2="15.9" y2="1.2"/>
            </g>
            </svg>
        </div>
        <span>|</span>
        <div class="b-nth-child" @click="toggleTabs('call_center')"
             :style="(activeTab('call_center') ? 'border: 1px solid #f57236;': '')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
               y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <g id="right-panel-support">
              <path class="st4"
                    d="M28.2,22.8h-1.5c-1.5,0-2.7-1.2-2.7-2.7v-5.1c0-1.5,1.2-2.7,2.7-2.7h1.5c1.5,0,2.7,1.2,2.7,2.7v5.1   C30.9,21.6,29.7,22.8,28.2,22.8z"/>
              <path class="st4"
                    d="M5.2,22.8H3.8c-1.5,0-2.7-1.2-2.7-2.7v-5.1c0-1.5,1.2-2.7,2.7-2.7h1.5c1.5,0,2.7,1.2,2.7,2.7v5.1   C7.9,21.6,6.7,22.8,5.2,22.8z"/>
              <path class="st4" d="M4.5,22.8c0,0,0.7,6,8.1,7.1"/>
              <path class="st4" d="M4.5,11.7C4.5,5.8,9.6,1,16,1s11.5,4.8,11.5,10.7"/>
              <ellipse class="st14" cx="15.2" cy="30" rx="3.1" ry="2"/>
            </g>
            </svg>
        </div>
        <span>|</span>
        <div class="b-nth-child" @click="toggleTabs('support')"
             :style="(activeTab('support') ? 'border: 1px solid #f57236;': '')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
               y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <g id="right-panel-tip">
              <path class="st4"
                    d="M20.7,10c1.9,0,3.4,1.5,3.4,3.4v2.7c0,4.5-2.8,8.2-8.2,8.2h-5.4c-4.3,0-8.2-3.6-8.3-8.1c0,0,0,0,0-0.1v-2.7   c0-1,0.5-2,1.1-2.5C4.5,9.9,6,10,7.5,10H20.7z"/>
              <path class="st4" d="M26.4,27.6l0,1.5c0,1-0.8,1.8-1.8,1.8H4.4c-1,0-1.8-0.9-1.8-1.9l0-1.5L26.4,27.6"/>
              <path class="st5" d="M23.3,10.9h3.4c2.1,0,3.8,1.7,3.4,3.5c-0.6,3-0.6,7-6.8,7"/>
              <path class="st4"
                    d="M8,6.5C7.6,6,7.5,5.6,7.6,5.3c0.1-0.8,1.1-1,1.4-2.1C9,3.1,9.1,2.5,8.9,2C8.6,1.5,8.2,1.2,8,1.1"/>
              <path class="st4"
                    d="M13.5,6.5c-0.5-0.5-0.5-1-0.4-1.2c0.1-0.8,1.1-1,1.4-2.1c0-0.2,0.2-0.7-0.1-1.2c-0.3-0.5-0.7-0.8-0.9-0.9"/>
              <path class="st4"
                    d="M19.1,6.5c-0.5-0.5-0.5-1-0.4-1.2c0.1-0.8,1.1-1,1.4-2.1c0-0.2,0.2-0.7-0.1-1.2c-0.3-0.5-0.7-0.8-0.9-0.9"/>
            </g>
            </svg>
        </div>
        <span>|</span>
        <div class="b-nth-child preserve" @click="()=>{toggleTabs('logout');logout()}"
             :style="(activeTab('logout') ? 'border: 1px solid #f57236;': '')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
               y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <g id="right-panel-submitted">
              <g>
                <polyline class="st4" points="7.5,9.6 16,1.1 24.5,9.6   "/>
                <line class="st4" x1="16" y1="1.1" x2="16" y2="22.2"/>
              </g>
              <polyline class="st4" points="24.3,26.8 24.3,30.9 7.3,30.9 7.3,26.8  "/>
            </g>
            </svg>
        </div>
      </div>
    </div>
    <div>
      <dial-pad v-if="activeTab('call_center')"></dial-pad>
      <system-notification v-if="activeTab('notification')"></system-notification>
      <div v-if="activeTab('support')">
        <div class="support-sup">
          <div class="cont-p">
            <p>Connect with support</p>
          </div>
          <div class="msg">
            <p>Notify via SMS for a (call back)</p>
            <a href="#" class="btn btn-site">
              <img src="../../public/img/sms.png" alt=""/>
            </a>
            <p class="sms">SMS</p>
          </div>
          <div class="msg">
            <p>Voice Call (immediate contact))</p>
            <a href="#" class="btn btn-site">
              <img src="../../public/img/phone.png" alt=""/>
            </a>
            <p class="sms">Call</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DialPad from './DialPad.vue';
import SystemNotification from './SystemNotification.vue';
import PerfectScrollbar from 'perfect-scrollbar';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DialPad,
    SystemNotification
  },
  data() {
    return {
      notifications: [],
      tabs: [
        {
          name: 'notification',
          display: false,
        },
        {
          name: 'call_center',
          display: false,
        },
        {
          name: 'support',
          display: false,
        },
        {
          name: 'logout',
          display: false,
        }
      ],
      user: {},
      profiledb: false
    }
  },
  methods: {
    fetchNotification() {
      this.$store.dispatch("notification/fetch").then((response) => {
        this.notifications = response.data.data.data;
        if (this.user.id > 2) {
          let unread = this.notifications.filter((element) => {
            return element.read === 0;
          });
        } else {
          let unread = this.notifications.filter((element) => {
            return element.admin_read === 0;
          });
        }
      }).catch((e) => {
        //this.$insProgress.finish();
      });
    },
    toggleTabs(name) {
      console.log(name)
      let index = this.tabs.findIndex(t => t.name === name);
      this.tabs = this.tabs.map(t => {
        t.display = false;
        return t;
      });
      if (index > -1) {
        this.tabs[index].display = !this.tabs[index].display;
      }
    },
    activeTab(name) {
      let index = this.tabs.findIndex(t => t.name === name);
      if (index > -1) {
        return this.tabs[index].display;
      }
      return false;
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    proflidb(name) {
      console.log(name)
      // let index = this.tabs.findIndex(t => t.name === name);
      // if(index > -1){
      //    console.log(index)
      //   return this.tabs[index].display;
      // }
      let profile_db = true;
      return profile_db;
    },
    logout() {
      this.$store.dispatch("api/logout", null);
      this.$cookie.delete('auth');
      this.$router.push('/login');
    }
  },
  mounted() {
    let docClasses = document.body.classList;
    docClasses.add('white-content');
    this.initScrollbar();
    this.user = this.$store.getters['api/user'];
  },
  created() {
    this.user = this.$store.getters['api/user'];
    this.fetchNotification();
  }

}
</script>
