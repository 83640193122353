//global file for registering of any plugins
import {
    initials,
    endpoint,
    is_useless,
    is_array,
    is_object,
    array_first,
    array_last,
    array_find,
    array_prev,
    chain_value,
    __esc,
} from "./utilities.js";

export default [
    //register plugins you want to use globally
    { name: 'initials', fn: initials },
    { name: 'endpoint', fn: endpoint },
    { name: 'is_useless', fn: is_useless },
    { name: 'is_array', fn: is_array },
    { name: 'is_object', fn: is_object },
    { name: 'array_first', fn: array_first },
    { name: 'array_last', fn: array_last },
    { name: 'array_find', fn: array_find },
    { name: 'array_prev', fn: array_prev },
    { name: 'chain_value', fn: chain_value },
    { name: '__esc', fn: __esc },
];