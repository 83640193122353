//global file for registering of any directives

import beforeCreate from "./lifecycle/beforeCreate.js";
import created from "./lifecycle/created.js";
import beforeMount from "./lifecycle/beforeMount.js";
import mounted from "./lifecycle/mounted.js";
import beforeUpdate from "./lifecycle/beforeUpdate.js";
import updated from "./lifecycle/updated.js";
import beforeUnmount from "./lifecycle/beforeUnmount.js";
import unmounted from "./lifecycle/unmounted.js";

export default [
    //register directives you want to use globally
    { name: "beforeCreate", directive: beforeCreate },
    { name: "created", directive: created },
    { name: "beforeMount", directive: beforeMount },
    { name: "mounted", directive: mounted },
    { name: "beforeUpdate", directive: beforeUpdate },
    { name: "updated", directive: updated },
    { name: "beforeUnmount", directive: beforeUnmount },
    { name: "unmounted", directive: unmounted }
];