//load all components that is being registered
import registery from "./register";

export default {
    install: (Vue) => {
        (process.env.VUE_APP_DEBUG == 'true' ? console.log(":: REGISTERING GLOBAL COMPONENTS :: [") : '');
        for (const iterator of registery) {
            Vue.component(iterator.name, iterator);
            (process.env.VUE_APP_DEBUG == 'true' ? console.log("REGISTERED COMPONENT :: ", iterator.name) : '');
        }
        (process.env.VUE_APP_DEBUG == 'true' ? console.log("]") : '');
    }
};