import Vue from "vue";
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios, axios);

const state = {
    conversation: {},
    xios: {} //this is the copy of axios but have pre-built headers with token use this as axios

};

const actions = {
    init(context, value) {
        if (context.rootState.api.auth.xios !== undefined) {
            context.commit("INIT", context.rootState.api.auth.xios);
            return true;
        } else {
            return false;
        }
    },
    participants(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('conversation/participants', value);
        }
    },
    list(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('conversation/list', value);
        }
    },
    voice_upload(context, value) {
        if (context.dispatch('init')) {
            return context.state.xios.post('conversation/voice_upload', value);
        }
    },
    unread_messages(context, value) {
      if (context.dispatch('init')) {
        return context.state.xios.post('conversation/unread_messages', value);
      }
    }
};

const mutations = {
    INIT(state, payload) {
        state.xios = payload;
    }

};
const getters = {

};

const conversation = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default conversation;
